<template>
    <modal :show="show" :size="cols.length < 4 ? 'md' : 'lg'" class="users-visibility-modal" @hide="$emit('hide')">
        <h2 slot="header">
            <span v-if="entity">
                {{ entity.name }}
            </span>
        </h2>
        <div slot="body">
            <div v-if="!rows.length" class="text-center">
                No data to show
            </div>
            <div v-else class="users-visibility-modal__table">
                <div
                    class="users-visibility-modal__cols pb-4"
                    :class="{
                        'users-visibility-modal__cols-5': cols.length === 5
                    }"
                >
                    <div v-for="col in cols" :key="col.key" class="f-w-bold">
                        {{ col.title }}
                        
                        <input
                            v-if="col.key === 'checked' && (isGodUser || isAdmin)"
                            :checked="isAllChecked"
                            type="checkbox"
                            class="form-checkbox ml-1"
                            @change="toggleAll"
                        />
                    </div>
                </div>
                <div class="users-visibility-modal__overflow-wrap">
                    <div
                        v-for="row in rows" :key="row.id"
                        class="users-visibility-modal__rows"
                        :class="{
                            'users-visibility-modal__rows-5': cols.length === 5
                        }"
                    >
                        <div v-for="col in cols" :key="`${col.key}_${row.id}`" class="py-2 pr-4 truncate">
                            <input v-if="col.key === 'checked' && (isGodUser || isAdmin)" v-model="row[col.key]" type="checkbox" class="form-checkbox ml-1" />
                            <span v-else-if="col.key === 'checked' && !(isGodUser || isAdmin)" />

                            <span v-else :title="row[col.key]">
                                {{ row[col.key] || '-' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="w-full flex justify-center">
            <button v-if="(isGodUser || isAdmin) && rows.length" class="btn" @click="submit">
                Save
            </button>
        </div>
    </modal>
</template>

<script>
import axios from 'axios';
import { requestErrors } from '~/utils/errors';

export default {
    props: {
        show: Boolean,
        entity: {
            type: Object,
            default: () => (null),
        },
        cols: {
            type: Array,
            default: () => [],
        },
        rows: {
            type: Array,
            default: () => [],
        },
        endpoint: {
            type: Function,
            default: null,
        },
        payloadFieldName: String,
    },
    computed: {
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        isAdmin() {
            return this.$store.getters['auth/isAdmin'];
        },
        isAllChecked() {
            return this.rows.every(row => row.checked);
        },
    },
    methods: {
        async submit() {
            if (!this.endpoint || typeof this.endpoint !== 'function' || !this.payloadFieldName) {
                return;
            }

            const payload = {
                [this.payloadFieldName]: this.rows.reduce((accum, row) => {
                    if (row.checked) {
                        accum.push(row.id);
                    }

                    return accum;
                }, []),
            };
            
            try {
                await axios.post(this.endpoint(this.entity.id), payload);

                this.$emit('update');

                this.$snotify.success('Template updated!');
                this.$emit('hide');
            } catch(error) {
                this.$snotify.error(requestErrors(error));
            }
        },
        toggleAll() {
            if (this.isAllChecked) {
                this.rows.forEach(row => row.checked = false);

                return;
            }

            this.rows.forEach(row => row.checked = true);
        },
    },
}
</script>
<style lang="scss">
.users-visibility-modal {
    &__cols, &__rows {
        display: grid;
        grid-template-columns: 40px repeat(3, 1fr);

        &-5 {
            display: grid;
            grid-template-columns: 40px repeat(4, 1fr);
        }
    }

    &__overflow-wrap {
        max-height: 360px;
        overflow-y: auto;
    }
}
</style>