<template>
  <div class="template-table-page">
    <card
        bodyClass="p-0 -mx-6 overflow-visible"
        class="border-radius-t-0 page-mode-table mb-6"
    >
        <app-table
        v-if="currentCompany || !isGodUser"
        id="templates"
        :columns="columns"
        row-key="id"
        :ref="this.tableRef"
        :endpoint="endpoint"
        sort-key="last_used_at"
        query-params
        no-cache
        paginate
        @row-dbclick="goTo"
        :filter-params="filterParams"
        >
        <template slot="after-tools">
          <router-link :to="{ name: 'transport.priceleadtime.template' }" class="btn">
            Back
          </router-link>
        </template>
        <div v-if="isGodUser" slot="tools">
          <multiselect
            v-model="currentCompany"
            :options="companyOptions"
            :allow-empty="false"
            track-by="id"
            label="name"
            class="w__320"
          >
            <span
              slot="noResult"
              >No elements found</span>
          </multiselect>
        </div>
        <div
            slot="cell"
            slot-scope="{ row, col, index }"
            :class="col.width"
            class="truncate"
        >
            <span v-if="columnNameMap.get(col.key)">
            {{ getName(row[columnNameMap.get(col.key)]) }}
            </span>
            <span v-else-if="dateFields.includes(col.key)">
            {{ formatDate(row[col.key]) }}
            </span>
            <span v-else-if="col.key === 'unifaun_pk'">
            {{ unifaunMap.get(Boolean(row[col.key])) }}
            </span>
            <span v-else-if="col.key === 'users'" :title="getUsersButtonTitle(row[col.key])">
              <button
                type="button"
                class="underline"
                @click="openUsersModal(row)"
              >
                {{ row[col.key].length }} selected
              </button>
            </span>
            <span v-else-if="col.key === 'actions'">
              <button v-if="isGodUser" @click="showRemoveModal(row)">
                <svg-importer icon-name="icons/close" :key="`icon-close_${col.key}_${index}`" />
              </button>
            </span>
            <span v-else>{{ row[col.key] || "-" }}</span>
        </div>
        </app-table>
    </card>

    <users-visibility-modal
      :show="isShowUsersModal"
      :entity="selectedTemplate"
      :cols="usersCols"
      :rows="usersRows"
      :endpoint="(val) => `/api/consignments/booking/templates/${val}/users`"
      payload-field-name="users"
      @hide="closeUsersModal"
      @update="updateRows"
    />

    <delete-modal
      :show="isShowRemoveModal"
      :selectedObject="selectedTemplate"
      name="template"
      @hide="closeRemoveModal"
      @delete="removeTemplate"
    />
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import AppTable from "~/components/vtable/AppTable";
import UsersVisibilityModal from '~/components/PriceLeadTime/UsersVisibilityModal.vue';

import columns from "~/data/templatesColumns.json";
import { requestErrors } from '~/utils/errors';
const dateFields = ["created_at", "updated_at", "last_used_at"];
const carrierFields = ["carrier", "carrier_service"];

const usersCols = [
  {
    title: '',
    key: 'checked',
  },
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Sites',
    key: 'sites',
  },
  {
    title: 'Area of responsibility',
    key: 'description',
  },
];

export default {
  components: { AppTable, UsersVisibilityModal, Multiselect },
  watch: {
    currentCompany(val) {
      if (!val) {
        return;
      }

      this.fetchUsers();
    },
  },
  computed: {
    filterParams() {
      return this.currentCompany ? { 'filter[organization_id]': this.currentCompany.id } : {};
    },
    isGodUser() {
      return this.$store.getters['auth/isGodUser'];
    },
    authSites() {
        return this.$store.getters["auth/sites"];
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin'];
    },
  },
  data() {
    return {
      tableRef: 'templates',
      endpoint: this.$apiUrl.consignments.booking.templates,
      isShowRemoveModal: false,
      isShowUsersModal: false,
      loading: false,
      columns,
      selectedTemplate: null,
      usersCols,
      usersRows: [],
      dateFields,
      unifaunMap: new Map([
        [true, "Yes"],
        [false, "No"],
      ]),
      columnNameMap: new Map([
        ["carrier_name", "carrier"],
        ["carrier_service_name", "carrier_service"],
        ["consignor_address_name", "consignor_address"],
        ["consignee_address_name", "consignee_address"],
        ["pickup_address_name", "pickup_address"],
        ["delivery_address_name", "delivery_address"],
      ]),
      companyOptions: [],
      currentCompany: null,
      users: [],
    };
  },
  methods: {
    showRemoveModal(data) {
      this.isShowRemoveModal = true;
      this.selectedTemplate = data;
    },
    closeRemoveModal() {
      this.isShowRemoveModal = false;
      this.selectedTemplate = null;
    },
    async fetchUsers() {
      try {
        const { data: { data } } = await axios.get(this.$apiUrl.users.list
          + `?filter[organization]=${this.currentCompany.id}&pageSize=1000`);
        this.users = data;
      } catch(error) {
        this.$snotify.error('Failed to fetch current organization users');
      }
    },
    getUsersButtonTitle(users) {
      return users.map(user => user.name)?.join(', ');
    },
    openUsersModal(template) {
      this.selectedTemplate = template;

      const templateUsersIdsSet = new Set(template.users.map(user => user.id));
      const companyUsers = this.users.filter(user =>  !templateUsersIdsSet.has(user.id));

      this.usersRows = [...template.users, ...companyUsers]
        .sort((a) => {
          if (templateUsersIdsSet.has(a.id)) {
            return -1;
          } else {
            return 1;
          }
        })
        .map(user => ({
            ...user,
            sites: user.sites?.map(site => site.name).join(', '),
            checked: templateUsersIdsSet.has(user.id),
          })
        );

      this.isShowUsersModal = true;
    },
    closeUsersModal() {
      this.isShowUsersModal = false;
      this.selectedTemplate = null;
      this.usersRows = [];
    },
    updateRows() {
      this.$refs[this.tableRef]?.table.fetchRowsSilent();
    },
    getHasName(key) {
      return Boolean(
        key.indexOf("_address") !== -1 ||
          key.indexOf("_contact") !== -1 ||
          carrierFields.includes(key)
      );
    },
    getName(data) {
      return data?.name || "-";
    },
    goTo({ data }) {
      this.$router.push({
        name: "transport.priceleadtime.template",
        params: { id: data.id },
      });
    },
    async removeTemplate() {
      try {
        await axios.delete(`${this.endpoint}/${this.selectedTemplate.id}`);
        this.$refs[this.tableRef]?.table?.fetchRowsSilent();

        this.$snotify.success('Template removed');
      } catch (error) {
        this.$snotify.error(requestErrors(error));
      }

      this.closeRemoveModal();
    },
  },
  async created() {
    if (!this.isGodUser) {
      return;
    }

    const { data: { data } } = await axios.get(this.$apiUrl.organizations.base + '?pageSize=1000');
    this.companyOptions = data;

    if (this.authSites?.length) {
      this.currentCompany = this.companyOptions.find(option => option.id === this.authSites[0]?.organization?.id);
    }
  },
};
</script>
<style lang="scss">
.template-table-page {
    .app-table__tbody {
        max-height: calc(100vh - 165px);
        min-height: 350px;
    }
}
</style>